/* eslint-disable react/jsx-props-no-spreading */
import * as React from "react";

const LightningShape: React.FC<React.SVGProps<SVGSVGElement>> = props => {
  return <svg width='114' height='113' viewBox='0 0 114 113' fill='none' xmlns='http://www.w3.org/2000/svg' {...props}>
            <g clipPath='url(#clip0_10174_765)'>
                <path opacity='0.8' d='M107.605 45.1535C107.605 40.1949 107.605 37.7157 106.913 35.4628C106.35 33.6303 105.456 31.9174 104.276 30.4093C102.826 28.5553 100.796 27.1433 96.7351 24.3194L71.3617 6.67336C66.0713 2.99411 63.426 1.15448 60.5427 0.475738C58.2039 -0.074828 55.7699 -0.0752512 53.4309 0.474502C50.5473 1.15224 47.9014 2.99094 42.6098 6.66836L17.2095 24.32C13.1464 27.1437 11.1149 28.5555 9.66323 30.4098C8.48248 31.9182 7.58813 33.6316 7.0248 35.4646C6.33221 37.7182 6.33221 40.1985 6.33221 45.1591V74.1314C6.33221 76.6117 6.33221 77.8519 6.67851 78.9787C6.96017 79.8952 7.40735 80.7519 7.99772 81.5061C8.72355 82.4333 9.73932 83.1391 11.7709 84.5509L42.6098 105.982C47.9014 109.66 50.5473 111.498 53.4309 112.176C55.7699 112.726 58.2039 112.725 60.5427 112.175C63.426 111.496 66.0713 109.656 71.3617 105.977L96.7351 88.3311C100.796 85.5072 102.826 84.0953 104.276 82.2412C105.456 80.7332 106.35 79.0202 106.913 77.1878C107.605 74.9349 107.605 72.4556 107.605 67.497V45.1535Z' fill='#00969B' />
                <path opacity='0.7' d='M97.6029 47.3892C97.6029 43.4224 97.6029 41.4389 97.0561 39.6366C96.6113 38.1707 95.9052 36.8003 94.9728 35.5939C93.8266 34.1106 92.2225 32.9811 89.0142 30.722L68.9661 16.6051C64.786 13.6617 62.696 12.19 60.4178 11.647C58.5698 11.2065 56.6467 11.2062 54.7985 11.646C52.5201 12.1882 50.4296 13.6592 46.2485 16.6011L26.1792 30.7224C22.9689 32.9813 21.3637 34.1108 20.2167 35.5943C19.2838 36.8009 18.5771 38.1717 18.132 39.6381C17.5848 41.441 17.5848 43.4252 17.5848 47.3937V70.5715C17.5848 72.5558 17.5848 73.5479 17.8584 74.4493C18.0809 75.1826 18.4343 75.8679 18.9007 76.4712C19.4742 77.213 20.2768 77.7777 21.882 78.9072L46.2485 96.0521C50.4296 98.9941 52.5201 100.465 54.7985 101.007C56.6467 101.447 58.5698 101.447 60.4178 101.006C62.696 100.463 64.786 98.9915 68.9661 96.0481L89.0142 81.9313C92.2225 79.6722 93.8266 78.5426 94.9728 77.0594C95.9052 75.8529 96.6113 74.4826 97.0561 73.0166C97.6029 71.2143 97.6029 69.2309 97.6029 65.264V47.3892Z' fill='#D6F7F7' />
                <mask id='mask0_10174_765' style={{
        maskType: "alpha"
      }} maskUnits='userSpaceOnUse' x='17' y='11' width='81' height='91'>
                    <path d='M97.6029 47.3853C97.6029 43.4185 97.6029 41.435 97.0561 39.6327C96.6113 38.1668 95.9052 36.7964 94.9728 35.59C93.8266 34.1067 92.2225 32.9772 89.0142 30.7181L68.9661 16.6012C64.786 13.6578 62.696 12.1861 60.4178 11.6431C58.5698 11.2026 56.6467 11.2023 54.7985 11.6421C52.5201 12.1843 50.4296 13.6553 46.2485 16.5972L26.1792 30.7185C22.9689 32.9774 21.3637 34.1069 20.2167 35.5904C19.2838 36.797 18.5771 38.1678 18.132 39.6342C17.5848 41.4371 17.5848 43.4213 17.5848 47.3898V70.5676C17.5848 72.5519 17.5848 73.544 17.8584 74.4454C18.0809 75.1786 18.4343 75.864 18.9007 76.4673C19.4742 77.2091 20.2768 77.7738 21.882 78.9033L46.2485 96.0482C50.4296 98.9902 52.5201 100.461 54.7985 101.003C56.6467 101.443 58.5698 101.443 60.4178 101.002C62.696 100.459 64.786 98.9876 68.9661 96.0442L89.0142 81.9274C92.2225 79.6683 93.8266 78.5387 94.9728 77.0555C95.9052 75.849 96.6113 74.4787 97.0561 73.0127C97.6029 71.2104 97.6029 69.227 97.6029 65.2601V47.3853Z' fill='#22BEC3' />
                </mask>
                <g mask='url(#mask0_10174_765)'>
                    <path fillRule='evenodd' clipRule='evenodd' d='M97.0561 39.6327C97.603 41.435 97.603 43.4184 97.603 47.3853V65.2601C97.603 69.227 97.603 71.2104 97.0561 73.0127C96.6114 74.4787 95.9052 75.849 94.9729 77.0555C93.8267 78.5387 92.2226 79.6682 89.0144 81.9273L89.0142 81.9274L68.9662 96.0442C64.7861 98.9876 62.696 100.459 60.4178 101.002C59.4893 101.224 58.5418 101.334 57.5944 101.333C51.3431 86.3295 53.8437 65.0748 57.5945 53.8223C43.952 69.7386 27.2082 73.2498 17.703 73.7558C17.5848 73.0004 17.5848 72.0674 17.5848 70.5676V47.3898C17.5848 43.4213 17.5848 41.4371 18.1321 39.6342C18.5772 38.1678 19.2838 36.797 20.2168 35.5904C21.3637 34.1069 22.9689 32.9774 26.1793 30.7185L46.2486 16.5972C50.4297 13.6553 52.5202 12.1843 54.7986 11.6421C56.6467 11.2023 58.5698 11.2026 60.4178 11.6431C62.696 12.1861 64.7861 13.6578 68.9661 16.6012L89.0142 30.718C92.2225 32.9772 93.8267 34.1067 94.9729 35.59C95.9052 36.7964 96.6114 38.1668 97.0561 39.6327Z' fill='#47CDD1' />
                    <g filter='url(#filter0_di_10174_765)'>
                        <path fillRule='evenodd' clipRule='evenodd' d='M63.912 31.0229C62.9175 30.088 63.3699 28.4893 64.7264 28.1451L75.51 25.4094C76.1883 25.2373 76.8099 25.8216 76.6289 26.4611L73.7515 36.6287C73.3895 37.9077 71.6939 38.3379 70.6993 37.403L70.4935 37.2095C69.4808 36.2575 68.9744 35.7816 68.4168 35.8822C67.8592 35.9828 67.5511 36.6057 66.9348 37.8515L60.3926 51.078C59.6721 52.5347 59.3118 53.2631 59.6419 53.7947C59.9721 54.3263 60.7847 54.3263 62.4098 54.3263H74.5341C75.3854 54.3263 75.773 55.3276 75.1215 55.844L55.301 71.556L43.3941 80.9947C40.2849 83.4594 38.7304 84.6917 38.0296 84.1236C37.3289 83.5555 38.2131 81.7797 39.9814 78.228L46.8646 64.4038C47.5969 62.933 47.963 62.1976 47.6292 61.6635C47.2953 61.1294 46.4738 61.1363 44.8308 61.1503L32.573 61.2544C31.7477 61.2614 31.3374 60.3145 31.9321 59.7752L64.2355 34.9086C65.2084 34.1597 65.6948 33.7853 65.7204 33.2661C65.746 32.7468 65.2987 32.3264 64.4041 31.4855L63.912 31.0229Z' fill='white' />
                    </g>
                </g>
            </g>
            <defs>
                <filter id='filter0_di_10174_765' x='23.211' y='22.5658' width='61.889' height='75.7597' filterUnits='userSpaceOnUse' colorInterpolationFilters='sRGB'>
                    <feFlood floodOpacity='0' result='BackgroundImageFix' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feOffset dy='5.62628' />
                    <feGaussianBlur stdDeviation='4.21971' />
                    <feComposite in2='hardAlpha' operator='out' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0 0 0 0 0 0.184314 0 0 0 0 0.192157 0 0 0 0.3 0' />
                    <feBlend mode='normal' in2='BackgroundImageFix' result='effect1_dropShadow_10174_765' />
                    <feBlend mode='normal' in='SourceGraphic' in2='effect1_dropShadow_10174_765' result='shape' />
                    <feColorMatrix in='SourceAlpha' type='matrix' values='0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0' result='hardAlpha' />
                    <feOffset dy='-2.81314' />
                    <feGaussianBlur stdDeviation='1.40657' />
                    <feComposite in2='hardAlpha' operator='arithmetic' k2='-1' k3='1' />
                    <feColorMatrix type='matrix' values='0 0 0 0 0.133333 0 0 0 0 0.745098 0 0 0 0 0.764706 0 0 0 0.25 0' />
                    <feBlend mode='normal' in2='shape' result='effect2_innerShadow_10174_765' />
                </filter>
                <clipPath id='clip0_10174_765'>
                    <rect width='112.526' height='112.526' fill='white' transform='translate(0.706177 0.0625)' />
                </clipPath>
            </defs>
        </svg>;
};

export default LightningShape;